import { useState, useEffect } from "react";
import "./IPhone14ProMax3.css";
import LandingFormMob from "../components/landing_form_mob";
import mobPic from '../assets/feature_img_mob.png';

const IPhone14ProMax3 = () => {

  const [firstFaq, setFirstFaq] = useState(true);
  const [secondFaq, setSecondFaq] = useState(true);
  const [threeFaq, setThreeFaq] = useState(true);
  const [fourFaq, setFourFaq] = useState(true);
  // const [screenSize, setScreenSize] = useState(getCurrentDimension());

  // useEffect(() => {
  //   const updateDimension = () => {
  //     setScreenSize(getCurrentDimension())
  //   }
  //   window.addEventListener('resize', updateDimension);

  //   return(() => {
  //       window.removeEventListener('resize', updateDimension);
  //   })
  // }, [screenSize])

  function firstFaqClicked() {
    setFirstFaq(!firstFaq);
    setSecondFaq(true);
    setThreeFaq(true);
    setFourFaq(true);
  }

  function secondFaqClicked() {
    setFirstFaq(true);
    setSecondFaq(!secondFaq);
    setThreeFaq(true);
    setFourFaq(true);
  }

  function threeFaqClicked() {
    setFirstFaq(true);
    setSecondFaq(true);
    setThreeFaq(!threeFaq);
    setFourFaq(true);
  }

  function fourFaqClicked() {
    setFirstFaq(true);
    setSecondFaq(true);
    setThreeFaq(true);
    setFourFaq(!fourFaq);
  }

  function isFaqOpen() {
    return firstFaq && secondFaq && threeFaq & fourFaq ? false : true;
  }

  //   function getCurrentDimension(){
  //     return {
  //       	width: window.innerWidth,
  //       	height: window.innerHeight
  //     }
  // }

  const overflowDivStyle = {
    overflowY: 'scroll'
  };

  const mobileCoverDiv = {
    display: 'flex',
    // alignItems: 'center',
    // justifyContent: 'center',
    flexDirection: 'column',
    // height: '00vh',
    width: '100%',
    // padding: '2rem'
  };

  const mobImgStyle = {
    // display: 'flex',
    // alignItems: 'center',
    // justifyContent: 'center',
    // height: '0vh',
    // width: '450px',
    width: '100%',
    backgroundColor: '#36474f',
    // padding: '0rem -0.15rem',
    // marginLeft: '90px',
    marginBottom: '30px',
    marginTop: '20px'
  };

  const mobFormAlign = {
    // alignItems: 'center',
    // justifyContent: 'center',
    width: '100%',
    // maring: '0px 10px'
    // marginRight: '10px'
  };

  return (
    <div className={isFaqOpen() ? "iphone-14-pro-max-3-2" : "iphone-14-pro-max-3"}>

      <img
        className="favicon-512x512-1"
        alt=""
        src="/favicon512x512-1@2x.png"
      />
      <b className="ansoftt-pos">ANSOFTT POS</b>
      <div className="column">
        <LandingFormMob />
        {/* <img style={mobImgStyle} src={mobPic} alt={'Feature'} /> */}
        {/* <div style={mobileCoverDiv}>
          <img style={mobImgStyle} src={mobPic} alt={'Feature'} />
          <div className="spacer"></div>
        </div> */}
      </div>

      <div className="section">
        <div className="divhome-contain">
          <div className="divwpb-wrapper">
            <div className="divscrolltop">
              <div className="list-item">
                <div className="heading-3">
                  <div className="modern-system-and">
                    modern system and easy to use
                  </div>
                </div>
              </div>
            </div>
            <div className="form-form-fieldset">
              <div className="input">
                <div className="divplaceholder">
                  <div className="enter-your-email">
                    Enter your email address
                  </div>
                </div>
              </div>
              <div className="input1">
                <b className="get-it-now">Get it now</b>
              </div>
            </div>
            <div className="divhome-slider">
              <img className="imacpng-icon" alt="" src="/img/pc.png" />
            </div>
          </div>
          <div className="center">
            <b className="powerful-software-with">Powerful software with a</b>
          </div>

        </div>
      </div>
      <div className="article">
        <div className="divimgholder">
          <img className="image-1-icon" alt="" src="/image-1@2x.png" />
        </div>
        <div className="divcol-12">
          <div className="header">
            <div className="strong-about">About Us</div>
            <div className="we-can-help">We can help your business grow.</div>
            <div className="p">
              <div className="our-mission-is">
                Our mission is to help businesses achieve their full potential
                by providing expert guidance and strategies for growth. With
                years of experience in various industries, our team of
                professionals understands the challenges that businesses face
                and are equipped to develop customized solutions to help
                businesses reach their goals.
              </div>
            </div>
            <img className="pseudo-icon" alt="" src="/pseudo@2x.png" />
          </div>
          <div className="list">
            <div className="item">
              <div className="div"></div>
              <div className="clients-satisfaction">Client's Satisfaction</div>
            </div>
            <div className="item1">
              <div className="div"></div>
              <div className="strategic-planning">Strategic Planning</div>
            </div>
            <div className="item2">
              <div className="div"></div>
              <div className="innovative-solutions">Innovative Solutions</div>
            </div>
            <div className="item3">
              <div className="div"></div>
              <div className="continuous-improvement">
                Continuous Improvement
              </div>
            </div>
            <div className="item4">
              <div className="div"></div>
              <div className="seamless-implementation">
                Seamless Implementation
              </div>
            </div>
            <div className="item5">
              <div className="div"></div>
              <div className="ongoing-support">Ongoing Support</div>
            </div>
          </div>
        </div>
      </div>
      <div className="section1">
        <div className="divrow">
          <div className="divtxt-holder">
            <div className="heading-4">
              <div className="streamline-your-business">
                Streamline Your Business Operations with our Advanced POS System
              </div>
            </div>
            <div className="p1">
              <div className="our-advanced-point">
                Our advanced Point of Sale (POS) system is designed to
                streamline your business operations, enhance efficiency, and
                elevate your customer experience to new heights. Our modern and
                intuitive Point of Sale (POS) system will transform your
                business by providing a seamless and hassle-free checkout
                experience for both you and your customers.
              </div>
            </div>
            <div className="strong-about1">About POS</div>
          </div>
          <img className="img03png-icon" alt="" src="/img03png@2x.png" />
        </div>
      </div>
      <div className="divprocess-holder">
        <div className="header1">
          <div className="heading-41">
            <div className="how-it-works">How It works</div>
          </div>
          <div className="p2">
            <div className="discover-the-power">
              Discover the power of Ansoftt POS: a user-friendly and efficient
              solution that revolutionizes your business transactions.
            </div>
          </div>
        </div>
        <div className="list1">
          <div className="item6">
            <div className="spannum">
              <div className="div6">1</div>
            </div>
            <div className="icon04png" />
            <div className="heading-31">Product Selection and Pricing</div>
            <div className="p3">
              <div className="customers-choose-products">
                Customers choose products or services, and the POS system
                displays their prices, discounts, and taxes.
              </div>
            </div>
            <img className="product-1-icon" alt="" src="/product-1.svg" />
          </div>
          <div className="item7">
            <div className="spannum1">
              <div className="div7">2</div>
            </div>
            <div className="heading-32">Payment Processing</div>
            <div className="p4">
              <div className="customers-provide-payment">
                {" "}
                Customers provide payment, and the POS system securely processes
                transactions through various methods like card readers or mobile
                payments.
              </div>
            </div>
            <img
              className="payment-system-1-icon"
              alt=""
              src="/paymentsystem-1.svg"
            />
          </div>
          <div className="item8">
            <div className="spannum2">
              <div className="div8">3</div>
            </div>
            <div className="icon06png" />
            <div className="heading-33">{`Receipt Generation & Inventory Update`}</div>
            <div className="p5">
              <div className="after-successful-payment">
                After successful payment, the POS system generates a receipt for
                customers and updates the business's inventory by deducting sold
                items.
              </div>
            </div>
            <img className="group-icon" alt="" src="/group.svg" />
          </div>
        </div>
        <div className="pseudo" />
      </div>
      <div className="section2">
        <img
          className="divelementor-background-overl-icon"
          alt=""
          src="/divelementorbackgroundoverlay@2x.png"
        />
        <div className="divjkit-icon-box-wrapper">
          <div className="div9"></div>
          <div className="divicon-box">
            <div className="heading-42">Inventory Management</div>
            <div className="picon-box-description">
              <div className="effortlessly-track-and">
                Effortlessly track and manage your inventory levels, ensuring
                optimal stock control and preventing stockouts.
              </div>
            </div>
          </div>
          <img className="inspection-1-icon" alt="" src="/inspection-1.svg" />
        </div>
        <div className="divjkit-icon-box-wrapper1">
          <div className="div9"></div>
          <div className="divicon-box1">
            <div className="heading-5">Customer Management</div>
            <div className="picon-box-description1">
              <div className="build-strong-customer">
                Build strong customer relationships by capturing customer
                information, tracking purchase history, and offering
                personalized promotions and discounts.
              </div>
            </div>
          </div>
          <img
            className="customer-care-1-icon"
            alt=""
            src="/customercare-1.svg"
          />
        </div>
        <div className="divjkit-icon-box-wrapper2">
          <div className="divicon-box2">
            <div className="heading-51">Reporting and Analytics</div>
            <div className="picon-box-description">
              <div className="gain-valuable-insights">
                Gain valuable insights into your business performance, sales
                trends, and customer behavior through comprehensive reporting
                and analytics features.
              </div>
            </div>
          </div>
          <img className="report-3-1" alt="" src="/report-3-1.svg" />
        </div>
      </div>
      <div className="divelementor-widget-wrap">
        <div className="heading-52">Our Services</div>
        <div className="heading-2">
          <div className="enjoy-our-special">Enjoy Our Special Features</div>
        </div>
        <div className="divelementor-widget-container">
          <div className="unlock-an-exceptional">
            Unlock an exceptional retail experience with Ansoftt Retail POS
          </div>
        </div>
      </div>
      <div className="divjkit-icon-box-wrapper3">
        <div className="divicon-box2">
          <div className="heading-53">Sales and Checkout</div>
          <div className="picon-box-description3">
            <div className="build-strong-customer">
              Streamline your sales process with fast and accurate checkout,
              enabling efficient transactions and reducing customer waiting
              time.
            </div>
          </div>
        </div>
        <img className="basket-1-icon" alt="" src="/basket-1.svg" />
      </div>
      <div className="divjkit-icon-box-wrapper4">
        <div className="divicon-box2">
          <div className="heading-54">Multi-Store Management</div>
          <div className="picon-box-description">
            <div className="gain-valuable-insights">
              Easily manage multiple store locations from a centralized system,
              synchronizing inventory, sales, and customer data for efficient
              operations across all branches.
            </div>
          </div>
        </div>
        <img className="shops-1-icon" alt="" src="/shops-1.svg" />
      </div>
      <div className="divrow1">
        <div className="divwhy-choose-one-right-img">
          <img className="image-2-icon" alt="" src="/image-2@2x.png" />
          <div className="divwhy-choose-one-right-img-">
            <b className="were-the-best">We’re the best Software</b>
          </div>
        </div>
        <div className="divwhy-choose-one-left">
          <div className="pwhy-choose-one-left-text">
            <div className="choose-us-as">
              Choose us as your retail POS provider and benefit from our trusted
              expertise, exceptional support, and innovative solutions tailored
              to drive your retail success.
            </div>
          </div>
          <div className="divsection-title">
            <div className="heading-21">
              <div className="benefits-of-product">
                Benefits of Product from Ansoftt
              </div>
            </div>
            <div className="why-choose-us">Why Choose Us?</div>
          </div>
          <div className="divwhy-choose-one-left-learn">
            <img className="icon" alt="" src="/icon.svg" />
            <div className="heading-43">
              <div className="trusted-and-reliable">
                Trusted and Reliable Partner for Your Retail Success
              </div>
            </div>
          </div>
          <div className="list2">
            <div className="item9">
              <img className="icon1" alt="" src="/icon1.svg" />
              <div className="trusted-expertise">Trusted Expertise</div>
            </div>
            <div className="item10">
              <img className="icon2" alt="" src="/icon2.svg" />
              <div className="seamless-integration">Seamless Integration</div>
            </div>
          </div>
          <div className="item11">
            <img className="icon3" alt="" src="/icon3.svg" />
            <div className="innovative-solutions1">Innovative Solutions</div>
          </div>
        </div>
      </div>
      <div className="section3">
        <div className="divauto-container">
          <div className="divsection-title1" />
          <div className="divtestimonials-one-wrapper">
            <img
              className="testimonials-one-left-pattern-icon"
              alt=""
              src="/testimonialsoneleftpatternpng@2x.png"
            />
            <img
              className="thm-shape3png-icon"
              alt=""
              src="/thmshape3png@2x.png"
            />
          </div>
        </div>
        <div className="our-product-usp">Our Product USP</div>
      </div>
      <div className="divtestimonials-one-wrapper1">
        <img
          className="testimonials-one-left-pattern-icon1"
          alt=""
          src="/testimonialsoneleftpatternpng1@2x.png"
        />
        <div className="divtestimonials-one-carousel" style={overflowDivStyle}>
          <div className="divowl-stage">
            <div className="divtestimonials-one-single-i">
              <b className="heading-44">Scalability for Growth</b>
              <div className="spacer-10"></div>
              <div className="ptestimonials-one-single-tex">
                <div className="as-your-business">
                  As your business expands, our POS solution grows with you. Add
                  new registers, locations, or users seamlessly, ensuring that
                  the system can accommodate your evolving needs without any
                  limitations.
                </div>
              </div>
            </div>
            <div className="spacer"></div>
            <div className="divtestimonials-one-single-i">
              <b className="heading-44">Seamless Integration</b>
              <div className="spacer-10"></div>
              <div className="ptestimonials-one-single-tex">
                <div className="as-your-business">
                  Our POS system seamlessly integrates with other essential business tools, such as accounting software, e-commerce platforms, and inventory management systems. This integration eliminates data silos, streamlines operations, and provides a unified view of your business processes.
                </div>
              </div>
            </div>
            <div className="spacer"></div>
            <div className="divtestimonials-one-single-i">
              <b className="heading-44">Comprehensive Reporting and Analytics</b>
              <div className="spacer-10"></div>
              <div className="ptestimonials-one-single-tex">
                <div className="as-your-business">
                  Gain valuable insights into your business performance with our robust reporting and analytics features. Track sales, inventory, and customer behavior, enabling data-driven decision-making and the ability to optimize operations for improved profitability.
                </div>
              </div>
            </div>
            {/* <div className="divtestimonials-one-single-i">
              <b className="heading-45">Seamless Integration</b>
              <div className="ptestimonials-one-single-tex1">
                <div className="our-pos-system">
                  Our POS system seamlessly integrates with other essential
                  business tools, such as accounting software, e-commerce
                  platforms, and inventory management systems. This integration
                  eliminates data silos, streamlines operations, and provides a
                  unified view of your business processes.
                </div>
              </div>
            </div> */}
            {/* <div className="divtestimonials-one-single-i">
              <b className="heading-46">
                Comprehensive Reporting and Analytics
              </b>
              <div className="ptestimonials-one-single-tex2">
                <div className="gain-valuable-insights1">
                  Gain valuable insights into your business performance with our
                  robust reporting and analytics features. Track sales,
                  inventory, and customer behavior, enabling data-driven
                  decision-making and the ability to optimize operations for
                  improved profitability.
                </div>
              </div>
            </div> */}
          </div>
          {/* <div className="divowl-dots">
            <div className="button" />
            <div className="button1" />
            <div className="button2" />
          </div> */}
        </div>
      </div>
      {/* <div className="section4">
        <div className="section5">
          <img
            className="logo-ipsum-white01png-icon"
            alt=""
            src="/logoipsumwhite01png@2x.png"
          />
          <img
            className="logo-ipsum-white02png-icon"
            alt=""
            src="/logoipsumwhite02png@2x.png"
          />
          <img
            className="logo-ipsum-white03png-icon"
            alt=""
            src="/logoipsumwhite03png@2x.png"
          />
          <img
            className="logo-ipsum-white04png-icon"
            alt=""
            src="/logoipsumwhite04png@2x.png"
          />
        </div>
      </div> */}
      <div className="div11">
        <div className="heading-34">
          <div className="frequently-asked-questions">
            Frequently Asked Questions
          </div>
          <b className="have-any-questions">Have Any Questions?</b>
        </div>
        <div className="div12">
          {firstFaq ?
            <div className="div17-2">
              <div className="pseudo6" />

              <div className="div18">
                <div className="is-ansoftt-pos-2">
                  {" "}
                  Is Ansoftt POS suitable for businesses of all sizes?
                </div>
                <div className="pseudo6" onClick={firstFaqClicked}>
                  <div className="minus">+</div>
                </div>

              </div>
            </div> :
            <div className="div16">
              <div className="pseudo3" />
              <div className="div17">
                <div className="yes-absolutely-ansoftt">
                  Yes, absolutely! Ansoftt POS is designed to accommodate businesses of all sizes, from small enterprises to large-scale operations. Our solution is scalable, allowing you to add more registers, locations, or users as your business expands. Whether you have a single store or multiple branches, our POS system can adapt to your growth and evolving needs.
                </div>
                <div className="div18">
                  <div className="is-ansoftt-pos">
                    {" "}
                    Is Ansoftt POS suitable for businesses of all sizes?
                  </div>
                  <div className="pseudo4" onClick={firstFaqClicked}>
                    <div className="minus">-</div>
                  </div>
                </div>
              </div>
            </div>}
          <div className="spacer"></div>
          {secondFaq ?
            <div className="div17-2">
              <div className="pseudo6" />

              <div className="div18">
                <div className="is-ansoftt-pos-2">
                  {" "}
                  Can Ansoftt POS integrate with my existing business systems?
                </div>
                <div className="pseudo6" onClick={secondFaqClicked}>
                  <div className="minus">+</div>
                </div>

              </div>
            </div> :
            <div className="div16">
              <div className="pseudo3" />
              <div className="div17">
                <div className="yes-absolutely-ansoftt">
                  Yes! Ansoftt POS is designed to work on all smart devices with web browser. It a cloud based solution, it can just run like plug and play system.
                </div>
                <div className="div18">
                  <div className="is-ansoftt-pos">
                    {" "}
                    Can Ansoftt POS integrate with my existing business systems?
                  </div>
                  <div className="pseudo4" onClick={secondFaqClicked}>
                    <div className="minus">-</div>
                  </div>
                </div>
              </div>
            </div>}
          <div className="spacer"></div>
          {threeFaq ?
            <div className="div17-2">
              <div className="pseudo6" />

              <div className="div18">
                <div className="is-ansoftt-pos-2">
                  {" "}
                  How easy is it to train my staff on Ansoftt POS?
                </div>
                <div className="pseudo6" onClick={threeFaqClicked}>
                  <div className="minus">+</div>
                </div>

              </div>
            </div> :
            <div className="div16">
              <div className="pseudo3" />
              <div className="div17">
                <div className="yes-absolutely-ansoftt">
                  It is very easy to train your employees for Ansoftt POS. This system is designed with simplicity and all options in the software are self-explanatory. It will only take one-day to get familiar with the system.
                </div>
                <div className="div18">
                  <div className="is-ansoftt-pos">
                    {" "}
                    How easy is it to train my staff on Ansoftt POS?
                  </div>
                  <div className="pseudo4" onClick={threeFaqClicked}>
                    <div className="minus">-</div>
                  </div>
                </div>
              </div>
            </div>}
          <div className="spacer"></div>
          {fourFaq ?
            <div className="div17-2">
              <div className="pseudo6" />

              <div className="div18">
                <div className="is-ansoftt-pos-2">
                  {" "}
                  What type of customer support is available with Ansoftt POS?
                </div>
                <div className="pseudo6" onClick={fourFaqClicked}>
                  <div className="minus">+</div>
                </div>

              </div>
            </div> :
            <div className="div16">
              <div className="pseudo3" />
              <div className="div17">
                <div className="yes-absolutely-ansoftt">
                  It is very easy to train your employees for Ansoftt POS. This system is designed with simplicity and all options in the software are self-explanatory. It will only take one-day to get familiar with the system.
                </div>
                <div className="div18">
                  <div className="is-ansoftt-pos">
                    {" "}
                    What type of customer support is available with Ansoftt POS?
                  </div>
                  <div className="pseudo4" onClick={fourFaqClicked}>
                    <div className="minus">-</div>
                  </div>
                </div>
              </div>
            </div>}


        </div>
      </div>
      {/* <div className={isFaqOpen() ? "iphone-14-pro-max-2" : "iphone-14-pro-max-1"}>
        <div>
          <div style={mobileCoverDiv}>
            <img style={mobImgStyle} src={mobPic} alt={'Feature'} />
          </div>

          <div style={mobFormAlign}>
            <LandingFormMob />
          </div>
        </div>
      </div> */}

    </div>
  );
};

export default IPhone14ProMax3;
